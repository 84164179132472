<template>
  <div
    class="w-full h-full flex flex-col mt-12 mb-8"
    style="align-items: center"
  >
    <div class="w-5/12 h-auto mt-5 mb-5 flex" style="justify-content: center">
      <icon icon-name="error-404" class-name="icon-Size mt-4 text-white" />
    </div>
    <div
      class="w-6/12 h-auto text-center"
      style="width: 610px; line-height:19px"
    >
      <p class="text-base text-jet mb-5">
        Your current plan does not allow access to this app.
      </p>
      <div class="text-flame flex w-full " style="justify-content: center">
        <p
          @click="handleManageSub"
          v-if="$AuthUser.isRoot"
          class="text-base cursor-pointer font-semibold ml-2"
        >
          Upgrade to access
        </p>
        <p v-else class="text-base font-semibold ml-2">
          Please contact your admin
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from "@/components/Icon";

export default {
  name: "Error404",
  components: {
    Icon
  },
  methods: {
    handleManageSub() {
      return window.location.replace(
        `${process.env.VUE_APP_CLOUDENLY}subscriptions/management`
      );
    }
  }
};
</script>

<style scoped>
.icon-Size {
  width: 280px;
  height: 280px;
}
</style>
